.scalelinesegmentedge {
    height: 10px;
    width: 2px;
}

.scalelinered {
    background-color: red;
}

.scalelineorange {
    background-color: orange;
}

.scalelinegreen {
    background-color: green;
}

.scalelineyellowgreen {
    background-color: yellowgreen;
}


.scalelineyellow {
    background-color: yellow;
}

.scalelinegradientright {
    background: linear-gradient(to right, yellowgreen, orange, red)
}

.scalelinegradientleft {
    background: linear-gradient(to left, green, yellowgreen, orange, red)
}

.scalelinesegmenthorizontalpart {
    height: 2px;
}

.scalelinesegment {
    display: flex;
    align-items: center;
    font-size: x-small;
    position: relative;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 17px solid #555;
    transform: translate(-50%, 0);
}

.scalelinecontainer {
    display: flex;
    align-items: end;
}